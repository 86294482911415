
//função HeadersGet
import { HeadersPost } from './headers'

//nome do banco
import { DbNome } from './connDb'


export function UrlServer() {
    const url = "https://server-stokemidia.vercel.app"
    //const url = 'https://apinode-77pf.onrender.com'
    // const url = 'http://localhost:4000'
    return url
}


export async function ConectURL(metodo, rota, msg, body, setfetchErro, id, setDados) {

    setfetchErro((fetchErro) => {
        return {
            ...fetchErro,
            btn: true,
            res: true
        }
    })



    await fetch(`${UrlServer()}/${rota}?id=${id || ''}`, {
        method: metodo,
        body: body ? JSON.stringify(body) : null,
        headers: HeadersPost()
    })
        .then(response => response.json())
        .then((body) => {


            if (body.connected) {

                //setDados(body.response)

                return setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'sucesso',
                        msg: msg,
                        metodoFetch: metodo,
                        data: body.body,
                        load: true,
                        navegacao: true
                    }
                })


            }



            if (!body.connected) {

                if (body.response === "Acesso negado") {
                    return setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: body.response
                        }
                    })
                }


                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        metodoFetch: metodo,
                        msg: body.response || ''

                    }
                })
            }
        })
        .catch(err => {

            setfetchErro((fetchErro) => {
                return {
                    ...fetchErro,
                    tipo: 'error',
                    msg: 'Erro ao inserir dados, tente novamente mais tarde.',
                    metodoFetch: metodo
                }
            })
        })
}


export async function ConectURLPost(rota,
    getContrato, setloadResponse, setsucessoFetch,
    setaguardeTempo, setErrrorResponse) {





    setloadResponse(true)



    await fetch(`${UrlServer()}/${rota}`, {
        method: 'POST',
        headers: HeadersPost(),
        body: JSON.stringify({ ...getContrato, dbNome: DbNome() })
    })
        .then(response => response.json())
        .then((body) => {

            if (body.response === "Acesso negado") {
                setErrrorResponse(true)
                setloadResponse(false)
                return
            }




            setloadResponse(false)
            setsucessoFetch(true)


            setTimeout(() => { setaguardeTempo(true) }, 2000)

        })
        .catch(err => {
            setloadResponse(false)
            setErrrorResponse(true)
        })

}
